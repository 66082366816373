import React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import Banner from "../components/Banner"
import TopHeading from "../components/TopHeading"
import ContactForm from "../components/ContactForm"

const contact = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Marsandi | Contact</title>
        <link rel="canonical" href="https://marsandi.com/contact" />
        <link rel="icon" href="https://marsandi.com/Marsandi-Logo-color.png" />
        <meta
          name="Description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        ​<meta name="twitter:card" content="summary_large_image"></meta>
        <meta name="twitter:site" contact="@devmarsandi"></meta>
        <meta name="twitter:creator" content="@devmarsandi"></meta>
        <meta
          name="twitter:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta
          name="twitter:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          name="twitter:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        ​
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Marsandi" />
        <meta
          property="og:title"
          content="Bring Your Business To The New Era"
        ></meta>
        <meta property="og:url" content="https://marsandi.com"></meta>
        <meta
          property="og:description"
          content="We provide custom digital design and development for your business needs. We work with you to fix manual actions and bottleneck processes with fully automated workflows."
        ></meta>
        <meta
          property="og:image"
          content="https://marsandi.com/Marsandi-Logo-color.png"
        ></meta>
        <meta property="og:image:alt" content="Logo"></meta>
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="text-customgray">
        <TopHeading
          title="Let's Get In Touch"
          subtitle="An investment for the future growth of your company"
        />
        <ContactForm />
        <img src="/banner-img-1.png" alt="Banner" width="1920" height="auto" />
        <Banner />
      </section>
    </Layout>
  )
}

export default contact
