import React from "react"
import { navigate } from "@reach/router"
import { Formik, Form, Field, ErrorMessage } from "formik"

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ContactForm = () => {
  return (
    <Formik
      initialValues={{
        first_name: "",
        last_name: "",
        phone_number: "",
        email_address: "",
        project_details: "",
      }}
      onSubmit={(values, actions) => {
        fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({ "form-name": "contact-form", ...values }),
        })
          .then(() => {
            actions.resetForm()
          })
          .catch(() => {
            console.log("error submission")
          })
          .finally(() => navigate("/thankyou"))
      }}
      validate={values => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
        const errors = {}
        if (!values.first_name) {
          errors.first_name = "First Name Required"
        }
        if (!values.last_name) {
          errors.last_name = "Last Name Required"
        }
        if (!values.phone_number) {
          errors.phone_number = "Phone Number Required"
        }
        if (!values.email_address || !emailRegex.test(values.email_address)) {
          errors.email_address = "Valid Email Required"
        }
        if (!values.project_details) {
          errors.project_details = "Project Details Required"
        }
        return errors
      }}
    >
      <div className="mb-10 sm:mt-0">
        <div className="grid justify-items-center align-middle">
          <div className="md:mt-0 w-full md:w-7/12 xl:w-5/12">
            <Form name="contact-form" data-netlify={true}>
              <div className="shadow overflow-hidden sm:rounded-md">
                <div className="px-4 py-5 bg-white sm:p-6">
                  <div className="md:grid md:grid-cols-6 md:gap-6">
                    <div className="md:col-span-4">
                      <label
                        htmlFor="first_name"
                        className="block text-sm font-medium text-customgray"
                      >
                        First name
                      </label>
                      <Field
                        type="text"
                        name="first_name"
                        id="first_name"
                        autocomplete="given-name"
                        className="mt-1 p-3 focus:ring-2 focus:ring-customblue focus:border-customblue block w-full sm:text-sm border-gray-100 border-2 rounded-md"
                      />
                      <ErrorMessage name="first_name" />
                    </div>

                    <div className="md:col-span-6">
                      <label
                        htmlFor="last_name"
                        className="block text-sm font-medium text-customgray"
                      >
                        Last name
                      </label>
                      <Field
                        type="text"
                        name="last_name"
                        id="last_name"
                        autocomplete="family-name"
                        className="mt-1 p-3 focus:ring-2 focus:ring-customblue focus:border-customblue block w-full sm:text-sm border-gray-100 border-2 rounded-md"
                      />
                      <ErrorMessage name="last_name" />
                    </div>

                    <div className="md:col-span-4">
                      <label
                        htmlFor="email_address"
                        className="block text-sm font-medium text-customgray"
                      >
                        Email address
                      </label>
                      <Field
                        type="text"
                        name="email_address"
                        id="email_address"
                        autocomplete="email"
                        className="mt-1 p-3 focus:ring-2 focus:ring-customblue focus:border-customblue block w-full sm:text-sm border-gray-100 border-2 rounded-md"
                      />
                      <ErrorMessage name="email_address" />
                    </div>

                    <div className="md:col-span-6">
                      <label
                        htmlFor="phone_number"
                        className="block text-sm font-medium text-customgray"
                      >
                        Phone Number
                      </label>
                      <Field
                        type="text"
                        name="phone_number"
                        id="phone_number"
                        className="mt-1 p-3 focus:ring-2 focus:ring-customblue focus:border-customblue block w-full sm:text-sm border-gray-100 border-2 rounded-md"
                      />
                      <ErrorMessage name="phone_number" />
                    </div>
                    <div className="md:col-span-12">
                      <label
                        htmlFor="project_details"
                        className="block text-sm font-medium text-customgray"
                      >
                        Project Details
                      </label>
                      <Field
                        name="project_details"
                        component="textarea"
                        id="project_details"
                        className="mt-1 p-3 focus:ring-2 focus:ring-customblue focus:border-customblue block w-full sm:text-sm border-gray-100 border-2 rounded-md"
                      />
                      <ErrorMessage name="project_details" />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 text-right sm:px-6">
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-customblue hover:bg-customdarkblue focus:outline-none focus:ring-2 focus:ring-offset-2"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Formik>
  )
}

export default ContactForm
